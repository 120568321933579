import styled from "styled-components";
import cloud from "../../assets/images/cloud.png";
import cloudOverlay from "../../assets/images/cloudOverlay.png";
import keys from "../../assets/images/keys.png";
import photo from "../../assets/images/photo.png";

export const StyledBackground = styled.img`
    position: absolute;
    top: 0;
    width: 100%;
    max-height: 170px;
    min-height: 120px;
`;

export const StyledButtonPhoto = styled.button`
    position: relative;
    background-color: transparent;
    background-image: url(${cloud});
    width: 190px;
    height: 75px;
    border: none;
    cursor: pointer;
    color: white;
    text-align: left;
    font-size: 26px;
    font-weight: 900;
    font-family: Calibri, Geneva, sans-serif;
    text-shadow: -2px -2px 0 #000, 0 -2px 0 #000, 2px -2px 0 #000, 2px 0 0 #000, 2px 2px 0 #000,
        0 2px 0 #000, -2px 2px 0 #000, -2px 0 0 #000;
    padding: 0 0 20px 50px;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 190px;
        height: 75px;
        background-image: url(${cloudOverlay});
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 30px;
        width: 34px;
        height: 45px;
        background-image: url(${photo});
    }
    @media (max-width: 800px) {
        margin-top: 50px;
    }
    @media (min-width: 800px) {
        margin-top: 100px;
    }
`;

export const StyledButtonKeys = styled.button`
    position: relative;
    background-color: transparent;
    background-image: url(${cloud});
    width: 190px;
    height: 75px;
    border: none;
    cursor: pointer;
    color: white;
    text-align: left;
    font-size: 26px;
    font-weight: 900;
    font-family: Calibri, Geneva, sans-serif;
    text-shadow: -2px -2px 0 #000, 0 -2px 0 #000, 2px -2px 0 #000, 2px 0 0 #000, 2px 2px 0 #000,
        0 2px 0 #000, -2px 2px 0 #000, -2px 0 0 #000;
    padding: 0 0 20px 40px;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 190px;
        height: 75px;
        background-image: url(${cloudOverlay});
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 10px;
        width: 50px;
        height: 37px;
        background-image: url(${keys});
    }
    @media (max-width: 800px) {
        margin-top: 50px;
    }
    @media (min-width: 800px) {
        margin-top: 100px;
    }
`;

export const StyledButton = styled.button`
    position: relative;
    background-color: transparent;
    background-image: url(${cloud});
    width: 190px;
    height: 75px;
    border: none;
    cursor: pointer;
    color: white;
    text-align: left;
    font-size: 26px;
    font-weight: 900;
    font-family: Calibri, Geneva, sans-serif;
    text-shadow: -2px -2px 0 #000, 0 -2px 0 #000, 2px -2px 0 #000, 2px 0 0 #000, 2px 2px 0 #000,
        0 2px 0 #000, -2px 2px 0 #000, -2px 0 0 #000;
    padding: 0 0 20px 45px;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 190px;
        height: 75px;
        background-image: url(${cloudOverlay});
    }
    @media (max-width: 800px) {
        margin-top: 50px;
    }
    @media (min-width: 800px) {
        margin-top: 100px;
    }
`;

export const StyledFlexBoxCenter = styled.div`
    display: flex;
    justify-content: space-around;
`;
